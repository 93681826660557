import React, { useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import AdminTrackCreate from './components/AdminTrackCreate';
import TrackPage from './components/TrackPage';
import TrackListingPage from './components/TrackListingPage';
import ProfilePage from './components/ProfilePage';
import ArtistPage from './components/ArtistPage';
import ProtectedRoute from './components/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';
import JAMZRewardPopup from './components/JAMZRewardPopup';
import Wallet from './components/Wallet';
import AdminChallengeManagement from './components/AdminChallengeManagement';
import AdminWithdrawals from './components/AdminWithdrawals';
import './styles/HeaderSideMenu.css';

export const AuthContext = createContext(null);
export const JAMZContext = createContext(null);

console.log('App.js: Initial render');
function App() {
  const [isJAMZRewardPopupOpen, setIsJAMZRewardPopupOpen] = useState(false);
  const [isLoadingJAMZ, setIsLoadingJAMZ] = useState(true);
  const [userJAMZ, setUserJAMZ] = useState({ jamz: 0, lastUpdated: null, isLoading: false });
  const [jamzAmount, setJamzAmount] = useState(0);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  const fetchUserJAMZ = useCallback(async () => {
    if (authState.isAuthenticated) {
      setIsLoadingJAMZ(true);
      try {
        const response = await axios.get('https://app.jamz.fun/api/users/wallet', {
          headers: { 'Authorization': `Bearer ${authState.token}` },
        });
        setUserJAMZ({ jamz: response.data.jamzBalance, lastUpdated: Date.now(), isLoading: false });
      } catch (error) {
        console.error('Error fetching user JAMZ:', error);
        setUserJAMZ(prevState => ({ ...prevState, isLoading: false }));
      } finally {
        setIsLoadingJAMZ(false);
      }
    } else {
      setUserJAMZ({ jamz: 0, lastUpdated: null, isLoading: false });
      setIsLoadingJAMZ(false);
    }
  }, [authState.isAuthenticated, authState.token]);

  useEffect(() => {
    console.log('App.js: Checking for stored token');
    const token = localStorage.getItem('token');
    if (token) {
      setAuthState({
        isAuthenticated: true,
        user: localStorage.getItem('username'),
        token: token,
      });
      console.log('App.js: User authenticated from stored token');
    }
  }, []);

  useEffect(() => {
    console.log('App.js: Fetching user JAMZ');
    fetchUserJAMZ();
    const jamzIntervalId = setInterval(fetchUserJAMZ, 60000);
    
    return () => {
      clearInterval(jamzIntervalId);
    };
  }, [fetchUserJAMZ]);

  const showJAMZRewardPopup = useCallback((amount) => {
    setJamzAmount(amount);
    setIsJAMZRewardPopupOpen(true);
  }, []);

  const refreshJAMZ = useCallback(() => {
    fetchUserJAMZ();
  }, [fetchUserJAMZ]);

  const login = useCallback((token, username) => {
    console.log('App.js: Login function called');
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    setAuthState({
      isAuthenticated: true,
      user: username,
      token: token,
    });
    console.log('App.js: User authenticated after login');
  }, []);

  const logout = useCallback(() => {
    console.log('App.js: Logout function called');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setAuthState({
      isAuthenticated: false,
      user: null,
      token: null,
    });
    setUserJAMZ({ jamz: 0, lastUpdated: null, isLoading: false });
    console.log('App.js: User logged out');
  }, []);

  const memoizedUserJAMZ = useMemo(() => ({ 
    ...userJAMZ, 
    refreshJAMZ, 
    showJAMZRewardPopup,
    isLoading: isLoadingJAMZ 
  }), [userJAMZ, refreshJAMZ, showJAMZRewardPopup, isLoadingJAMZ]);

  const authContextValue = useMemo(() => ({
    ...authState,
    login,
    logout,
  }), [authState, login, logout]);

  const jamzContextValue = useMemo(() => ({
    userJAMZ: memoizedUserJAMZ,
    isLoadingJAMZ,
    refreshJAMZ,
    showJAMZRewardPopup,
  }), [memoizedUserJAMZ, isLoadingJAMZ, refreshJAMZ, showJAMZRewardPopup]);

  console.log('App.js: Rendering with authState:', authState);

  return (
    <Router>
      <AuthContext.Provider value={authContextValue}>
        <JAMZContext.Provider value={jamzContextValue}>
          <div className="App"> 
            <Header />
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset/:token" element={<ResetPassword />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/tracks" element={<TrackListingPage />} />
                <Route path="/profile/:username" element={<ProfilePage />} />
                <Route path="/artist/:artistId" element={<ArtistPage />} />
                <Route 
                  path="/admin/tracks/create" 
                  element={
                    <ProtectedRoute>
                      <AdminTrackCreate />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/admin/challenges" 
                  element={
                    <ProtectedRoute>
                      <AdminChallengeManagement />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/admin/withdrawals" 
                  element={
                    <ProtectedRoute>
                      <AdminWithdrawals />
                    </ProtectedRoute>
                  } 
                />
                <Route path="/track/:trackId" element={<TrackPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </main>
            <Footer />
            <JAMZRewardPopup 
              isOpen={isJAMZRewardPopupOpen}
              onClose={() => { 
                setIsJAMZRewardPopupOpen(false); 
                refreshJAMZ(); 
              }}
              jamzAmount={jamzAmount}
            />
          </div>
        </JAMZContext.Provider>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;