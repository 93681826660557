import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaSignInAlt, FaUserPlus, FaSignOutAlt, FaUser, FaWallet, FaTimes, FaBars } from 'react-icons/fa';
import useSound from 'use-sound';
import '../styles/HeaderSideMenu.css';

const menuVariants = {
  closed: { scale: 0.8, opacity: 0 },
  open: { scale: 1, opacity: 1 },
};

const itemVariants = {
  closed: { opacity: 0, y: 20 },
  open: { opacity: 1, y: 0 },
};

const containerVariants = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
};

const transition = {
  type: 'spring',
  stiffness: 300,
  damping: 30,
};

const MenuItem = React.memo(({ icon: Icon, text, onClick, linkTo, className }) => (
  <motion.div variants={itemVariants} transition={transition}>
    {linkTo ? (
      <Link to={linkTo} onClick={onClick} className={className}>
        <Icon /> {text}
      </Link>
    ) : (
      <button onClick={onClick} className={className}>
        <Icon /> {text}
      </button>
    )}
  </motion.div>
));

function SideMenu({ isOpen, onClose, userJamzBalance, balanceLoading, username, isAdmin, handleLogout, handleWalletClick }) {
  const menuRef = useRef(null);
  const [playMenuSound] = useSound(`${process.env.PUBLIC_URL}/menu.wav`);

  const handleItemClick = useCallback((e, callback) => {
    if (callback) {
      callback();
    }
    onClose();
    playMenuSound();
  }, [onClose, playMenuSound]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !event.target.classList.contains('menu-toggle-button')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      playMenuSound();
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, playMenuSound]);


  return (
    <>
      <button 
        className={`menu-toggle-button ${isOpen ? 'open' : ''}`} 
        onClick={onClose}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={`circular-menu ${isOpen ? 'open' : ''}`}
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
            transition={transition}
            ref={menuRef}
          >
            <motion.nav className="menu-items" variants={containerVariants}>
              <MenuItem icon={FaHome} text="Home" linkTo="/" onClick={(e) => handleItemClick(e)} />
              {username ? (
                <>
                  <MenuItem icon={FaUser} text="Profile" linkTo={`/profile/${username}`} onClick={(e) => handleItemClick(e)} />
                  <MenuItem 
                    icon={FaWallet} 
                    text={
                      balanceLoading || userJamzBalance === undefined
                        ? 'Loading...'
                        : typeof userJamzBalance === 'number'
                        ? `${userJamzBalance.toFixed(2)} JAMZ`
                        : '0.00 JAMZ'
                    }
                    onClick={(e) => handleItemClick(e, handleWalletClick)} 
                    className="wallet-display"
                  />
                  {isAdmin && (
                    <MenuItem 
                      icon={FaUser} text="Create Track" linkTo="/admin/tracks/create" 
                      onClick={(e) => handleItemClick(e)} />
                  )}
                  <MenuItem 
                    icon={FaSignOutAlt} 
                    text="Logout" 
                    onClick={(e) => handleItemClick(e, handleLogout)} 
                    className="logout-button"
                  />
                </>
              ) : (
                <>
                  <MenuItem icon={FaSignInAlt} text="Login" linkTo="/login" onClick={(e) => handleItemClick(e)} className="login-button" />
                  <MenuItem icon={FaUserPlus} text="Sign Up" linkTo="/signup" onClick={(e) => handleItemClick(e)} className="signup-button" />
                </>
              )}
            </motion.nav>
          </motion.div>
        )}
      </AnimatePresence>
      {isOpen && (
        <motion.div
          className="menu-backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        />
      )}
    </>
  );
}

export default React.memo(SideMenu);