import React from 'react';
import { FaStar } from 'react-icons/fa';
import '../styles/JAMZComponents.css';

function JAMZDisplay({ jamz, isLoading, refreshJAMZ }) {
  return (
    <div className="jamz-display" onClick={refreshJAMZ}>
      <FaStar className="jamz-star" />
      <span className="jamz-text">
        {isLoading ? 'Loading...' : `${jamz} JAMZ`}
      </span>
    </div>
  );
}

export default JAMZDisplay;