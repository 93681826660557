import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaUserPlus, FaSpinner } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { AuthContext } from '../App';  // Import AuthContext
import '../styles.css';

const schema = yup.object().shape({
  username: yup.string().required('Username is required').min(3, 'Username must be at least 3 characters'),
  email: yup.string().required('Email is required').email('Invalid email format'),
  password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

function Signup() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useContext(AuthContext);  // Use AuthContext

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadGoogleScript();
  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google && process.env.REACT_APP_GOOGLE_CLIENT_ID) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignIn
      });
      window.google.accounts.id.renderButton(
        document.getElementById("googleSignUp"),
        { theme: "outline", size: "large", text: "signup_with" }
      );
    } else {
      console.error('Google Sign-In script not loaded properly or Client ID not set');
    }
  };

  const sendWelcomeEmail = (username, email) => {
    const templateParams = {
      to_name: username,
      to_email: email,
    };

    emailjs.send(
      'YOUR_SERVICE_ID',
      'YOUR_TEMPLATE_ID',
      templateParams,
      'YOUR_USER_ID'
    )
      .then((response) => {
        console.log('Welcome email sent successfully:', response);
      }, (error) => {
        console.error('Error sending welcome email:', error);
      });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError('');
    try {
      const { refCode, trackId } = location.state || {};
      const response = await axios.post('https://app.jamz.fun/api/users/signup', {
        username: data.username,
        email: data.email,
        password: data.password,
        referralCode: refCode
      });

      if (response.data.token) {
        login(response.data.token, response.data.username);  // Use login function from AuthContext
        sendWelcomeEmail(data.username, data.email);

        if (trackId) {
          navigate(`/track/${trackId}`);
        } else {
          navigate('/');
        }
      } else {
        setError('Signup successful, but no token received. Please try logging in.');
      }
    } catch (error) {
      console.error('Signup error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'An error occurred during signup. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async (response) => {
    setIsLoading(true);
    setError('');
    try {
      const { refCode, trackId } = location.state || {};
      const result = await axios.post('https://app.jamz.fun/api/google-auth', {
        token: response.credential,
        referralCode: refCode
      });
      
      if (result.data.token) {
        login(result.data.token, result.data.username);  // Use login function from AuthContext
        sendWelcomeEmail(result.data.username, result.data.email);

        if (trackId) {
          navigate(`/track/${trackId}`);
        } else {
          navigate('/');
        }
      } else {
        setError('Google sign-in successful, but no token received. Please try logging in.');
      }
    } catch (error) {
      console.error('Google sign-in error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'An error occurred with Google signup. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="signup container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="form-container">
        <motion.h2
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ type: 'spring', stiffness: 120 }}
        >
          Sign up for Jamz.fun
        </motion.h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              {...register('username')}
              className={errors.username ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.username && <p className="error-message">{errors.username.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              {...register('email')}
              className={errors.email ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              {...register('password')}
              className={errors.password ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              {...register('confirmPassword')}
              className={errors.confirmPassword ? 'error' : ''}
              disabled={isLoading}
            />
            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
          </div>
          <motion.button
            type="submit"
            className="btn btn-primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="icon-spin" /> : <FaUserPlus />} Sign Up
          </motion.button>
        </form>
        <div id="googleSignUp"></div>
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </motion.div>
  );
}

export default Signup;