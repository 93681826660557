import React from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaMusic, FaChartLine, FaDollarSign } from 'react-icons/fa';

const MarketingEcosystemFund = () => {
  return (
    <motion.section
      className="marketing-ecosystem-fund"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <div className="fund-content">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          🚀 Jamz.fun $250,000 Artists Marketing Ecosystem Fund
        </motion.h2>
        <motion.p
          className="fund-description"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
          We're putting $125,000 USDT & $125,000 JAMZ into the hands of artists to kickstart their viral music campaigns!
        </motion.p>
        <div className="fund-features">
          <motion.div 
            className="fund-feature"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.2)" }}
          >
            <FaMusic className="feature-icon" />
            <h3>Launch Your Campaign</h3>
            <p>Get the resources you need to make your music go viral</p>
          </motion.div>
          <motion.div 
            className="fund-feature"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.2)" }}
          >
            <FaChartLine className="feature-icon" />
            <h3>Boost Your Reach</h3>
            <p>Tap into our network and skyrocket your audience</p>
          </motion.div>
          <motion.div 
            className="fund-feature"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.2)" }}
          >
            <FaDollarSign className="feature-icon" />
            <h3>Monetize Your Talent</h3>
            <p>Turn your passion into a sustainable career</p>
          </motion.div>
        </div>
        <motion.a
          href="https://docs.google.com/forms/d/e/1FAIpQLSck-sNU-X1ZD5jiVP4wVYPVEY0Ae7NLYAyuhQPLx9xSEh29vQ/viewform"
          target="_blank"
          rel="noopener noreferrer"
          className="apply-now-btn"
          whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.5)" }}
          whileTap={{ scale: 0.95 }}
        >Apply Now</motion.a>
      </div>
      <div className="fund-background">
        <div className="animated-notes">
          {[...Array(20)].map((_, index) => (
            <motion.span
              key={index}
              className="note"
              initial={{ y: 0, opacity: 1 }}
              animate={{ 
                y: -1000,
                opacity: [1, 0.8, 0.6, 0.4, 0.2, 0],
                transition: { 
                  duration: Math.random() * 10 + 10,
                  repeat: Infinity,
                  delay: Math.random() * 5
                }
              }}
              style={{
                left: `${Math.random() * 100}%`,
                fontSize: `${Math.random() * 20 + 10}px`
              }}
            >
              ♪
            </motion.span>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default MarketingEcosystemFund;