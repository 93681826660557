import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaTiktok, FaInstagram, FaYoutube, FaPlus } from 'react-icons/fa';

function AdminTrackCreate() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [jamz, setJamz] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [challenges, setChallenges] = useState([
    { platform: 'tiktok', rewardType: 'jamz', rewardAmount: '' },
    { platform: 'instagram', rewardType: 'jamz', rewardAmount: '' },
    { platform: 'youtube', rewardType: 'jamz', rewardAmount: '' }
  ]);
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState('');
  const [newArtistName, setNewArtistName] = useState('');
  const [newArtistImage, setNewArtistImage] = useState('');
  const [newArtistBio, setNewArtistBio] = useState('');
  const [newArtistTwitter, setNewArtistTwitter] = useState('');
  const [newArtistInstagram, setNewArtistInstagram] = useState('');
  const [newArtistYoutube, setNewArtistYoutube] = useState('');
  const [newArtistTiktok, setNewArtistTiktok] = useState('');
  const [isCreatingArtist, setIsCreatingArtist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchArtists();
    fetchTracks();
  }, []);

  const fetchArtists = async () => {
    try {
      const response = await axios.get('https://app.jamz.fun/api/artists', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setArtists(response.data);
    } catch (error) {
      console.error('Error fetching artists:', error);
      setError('Failed to fetch artists. Please try again.');
    }
  };
  const fetchTracks = async () => {
    try {
      const response = await axios.get('https://app.jamz.fun/api/tracks', {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      setTracks(response.data);
    } catch (error) {
      console.error('Error fetching tracks:', error);
      setError('Failed to fetch tracks. Please try again.');
    }
  };

  const handleEditTrack = async (trackId) => {
    try {
      const track = tracks.find(t => t._id === trackId);
      if (!track) return;

      const updatedTrack = { ...track, name, description, jamz: parseInt(jamz), youtubeLink };
      const response = await axios.put(`https://app.jamz.fun/api/tracks/${trackId}`, updatedTrack, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Track updated:', response.data);
      setSuccess('Track updated successfully!');
      fetchTracks();
    } catch (error) {
      console.error('Error updating track:', error);
      setError('Failed to update track. Please try again.');
    }
  };

  const handleDeleteTrack = async (trackId) => {
    try {
      console.log('Attempting to delete track with ID:', trackId);
      console.log('Full track object:', JSON.stringify(tracks.find(t => t._id === trackId), null, 2));
      const token = localStorage.getItem('token') || localStorage.getItem('x-auth-token');
      if (!token) {
        console.error('No authentication token found in localStorage');
        setError('Authentication token not found. Please log in again.');
        return;
      }

      const trackToDelete = tracks.find(t => t._id === trackId);
      if (!trackToDelete) {
        console.error('Track not found in local state:', trackId);
        setError('Track not found. Please refresh the page and try again.');
        return;
      }

      console.log('Track to be deleted:', JSON.stringify(trackToDelete, null, 2));
      console.log('Token being used:', token);

      const response = await axios.delete(`https://app.jamz.fun/api/tracks/${trackId}`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'x-auth-token': token
        }
      });

      console.log('Delete response:', JSON.stringify(response.data, null, 2));
      console.log('Track deleted successfully:', trackId);
      setSuccess('Track deleted successfully!');
      fetchTracks();
    } catch (error) {
      console.error('Error deleting track:', error.message);
      if (error.response) {
        console.error('Server responded with:', error.response.status, error.response.statusText, JSON.stringify(error.response.data, null, 2));
        if (error.response.status === 401) {
          console.error('Unauthorized access. Token might be invalid or expired.');
          setError('Unauthorized. Please log in again.');
        } else {
          setError(`Failed to delete track: ${error.response.data.message || 'Unknown error'}. Status: ${error.response.status}. Details: ${JSON.stringify(error.response.data, null, 2)}`);
        }
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response received from server. Please check your internet connection and try again.');
      } else {
        setError(`An unexpected error occurred: ${error.message || 'Unknown error'}. Full error: ${JSON.stringify(error, null, 2)}`);
      }
    }
  };

  const handleChallengeChange = (index, field, value) => {
    const updatedChallenges = [...challenges];
    updatedChallenges[index][field] = value;
    setChallenges(updatedChallenges);
    console.log('Updated challenges:', updatedChallenges);
  };

  const validateForm = () => {
    if (!name || !description || !jamz || !youtubeLink || !selectedArtist) {
      setError('Please fill in all required fields');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const filteredChallenges = challenges.filter(challenge => challenge.rewardAmount !== '');
    setLoading(true);
    try {
      console.log('Submitting track with challenges:', filteredChallenges);
      const response = await axios.post('https://app.jamz.fun/api/tracks', {
        name,
        description,
        jamz: parseInt(jamz),
        youtubeLink,
        challenges: filteredChallenges,
        artistId: selectedArtist
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log('Track created:', response.data);
      setSuccess('Track created successfully!');
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Error creating track:', error.response?.data || error.message);
      setError(error.response?.data?.message || 'An error occurred while creating the track');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCreateArtist = async () => {
    if (!newArtistName || !newArtistImage || !newArtistBio) {
      setError('Please provide artist name, image link, and bio');
      return;
    }
    try {
      const response = await axios.post('https://app.jamz.fun/api/artists', {
        name: newArtistName,
        imageUrl: newArtistImage,
        bio: newArtistBio,
        twitter: newArtistTwitter,
        instagram: newArtistInstagram,
        youtube: newArtistYoutube,
        tiktok: newArtistTiktok
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      const newArtist = response.data;
      setArtists([...artists, newArtist]);
      setSelectedArtist(newArtist._id);
      setIsCreatingArtist(false);
      setNewArtistName('');
      setNewArtistImage('');
      setNewArtistBio('');
      setNewArtistTwitter('');
      setNewArtistInstagram('');
      setNewArtistYoutube('');
      setNewArtistTiktok('');
    } catch (error) {
      console.error('Error creating artist:', error);
      setError('Failed to create artist. Please try again.');
    }
  };

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case 'tiktok': return <FaTiktok />;
      case 'instagram': return <FaInstagram />;
      case 'youtube': return <FaYoutube />;
      default: return null;
    }
  };

  return (
    <motion.div className="container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <motion.div className="form-container" initial={{ y: -20 }} animate={{ y: 0 }} transition={{ type: 'spring', stiffness: 120 }}>
        <h2>Create New Track</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit} className="admin-track-form">
          <div className="form-group">
            <label htmlFor="name">Track Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter track name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter track description"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="jamz">JAMZ:</label>
            <input
              type="number"
              id="jamz"
              value={jamz}
              onChange={(e) => setJamz(e.target.value)}
              placeholder="Enter JAMZ value"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="youtubeLink">YouTube Link:</label>
            <input
              type="url"
              id="youtubeLink"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
              placeholder="Enter YouTube link"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="artist">Artist:</label>
            <select
              id="artist"
              value={selectedArtist}
              onChange={(e) => {
                if (e.target.value === 'create') {
                  setIsCreatingArtist(true);
                } else {
                  setSelectedArtist(e.target.value);
                }
              }}
              required
            >
              <option value="">Select an artist</option>
              {artists.map(artist => (
                <option key={artist._id} value={artist._id}>{artist.name}</option>
              ))}
              <option value="create">Create new artist</option>
            </select>
          </div>
          {isCreatingArtist && (
            <div className="create-artist-form">
              <div className="form-group">
                <label htmlFor="newArtistName">New Artist Name:</label>
                <input
                  type="text"
                  id="newArtistName"
                  value={newArtistName}
                  onChange={(e) => setNewArtistName(e.target.value)}
                  placeholder="Enter new artist name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistImage">Artist Image Link:</label>
                <input
                  type="url"
                  id="newArtistImage"
                  value={newArtistImage}
                  onChange={(e) => setNewArtistImage(e.target.value)}
                  placeholder="Enter artist image link"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistBio">Artist Bio:</label>
                <textarea
                  id="newArtistBio"
                  value={newArtistBio}
                  onChange={(e) => setNewArtistBio(e.target.value)}
                  placeholder="Enter artist bio"
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistTwitter">Twitter:</label>
                <input
                  type="url"
                  id="newArtistTwitter"
                  value={newArtistTwitter}
                  onChange={(e) => setNewArtistTwitter(e.target.value)}
                  placeholder="Enter Twitter profile URL"
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistInstagram">Instagram:</label>
                <input
                  type="url"
                  id="newArtistInstagram"
                  value={newArtistInstagram}
                  onChange={(e) => setNewArtistInstagram(e.target.value)}
                  placeholder="Enter Instagram profile URL"
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistYoutube">YouTube:</label>
                <input
                  type="url"
                  id="newArtistYoutube"
                  value={newArtistYoutube}
                  onChange={(e) => setNewArtistYoutube(e.target.value)}
                  placeholder="Enter YouTube channel URL"
                />
              </div>
              <div className="form-group">
                <label htmlFor="newArtistTiktok">TikTok:</label>
                <input
                  type="url"
                  id="newArtistTiktok"
                  value={newArtistTiktok}
                  onChange={(e) => setNewArtistTiktok(e.target.value)}
                  placeholder="Enter TikTok profile URL"
                />
              </div>
              <motion.button
                type="button"
                className="btn btn-secondary"
                onClick={handleCreateArtist}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <FaPlus /> Create Artist
              </motion.button>
            </div>
          )}
          <h3>Challenges</h3>
          {challenges.map((challenge, index) => (
            <div key={challenge.platform} className="form-group challenge-group">
              <label htmlFor={`challenge-${challenge.platform}`}>  
                {getPlatformIcon(challenge.platform)} {challenge.platform.charAt(0).toUpperCase() + challenge.platform.slice(1)} Challenge Reward:
                <select value={challenge.rewardType} onChange={(e) => handleChallengeChange(index, 'rewardType', e.target.value)}>
                  <option value="jamz">Jamz</option>
                  <option value="usdt">USDT</option>
                </select>
              </label>
              <input
                type="number"
                id={`challenge-${challenge.platform}-amount`}
                value={challenge.rewardAmount}
                onChange={(e) => handleChallengeChange(index, 'rewardAmount', e.target.value)}
                placeholder={`Enter reward amount for ${challenge.platform} challenge`}
              />
            </div>
          ))}
          <motion.button
            type="submit"
            className="btn btn-primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Create Track with Challenges'}
          </motion.button>
        </form>
       <h3>Existing Tracks</h3>
       {tracks.length === 0 ? (
         <p>No tracks available.</p>
       ) : (
         <ul className="track-list">
           {tracks.map(track => (
             <li key={track._id} className="track-item">
               <p><strong>Name:</strong> {track.name}</p>
               <p><strong>Description:</strong> {track.description}</p>
               <p><strong>JAMZ:</strong> {track.jamz}</p>
               <p><strong>Artist:</strong> {track.artistName}</p>
               <div className="track-actions">
                 <button className="btn btn-secondary" onClick={() => handleEditTrack(track._id)}>Edit</button>
                  <button className="btn btn-danger" onClick={() => handleDeleteTrack(track._id)}>Delete</button>
               </div>
             </li>
           ))}
         </ul>
       )}
       
      </motion.div>
    </motion.div>
  );
}

export default AdminTrackCreate;