import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaPlay, FaStar } from 'react-icons/fa';

const TrackListingPage = () => {
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://app.jamz.fun/api/tracks');
        setTracks(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch tracks. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchTracks();
  }, []);

  const getYouTubeThumbnail = (youtubeLink) => {
    const videoId = extractYouTubeId(youtubeLink);
    return videoId ? `https://img.youtube.com/vi/${videoId}/mqdefault.jpg` : 'https://via.placeholder.com/300x200';
  };

  const extractYouTubeId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  if (isLoading) {
    return <div className="loading">Loading tracks...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="track-listing-page">
      <h1 className="page-title">Discover Tracks</h1>
      <div className="tracks-grid">
        {tracks.map((track) => (
          <motion.div
            key={track._id}
            className="track-item"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to={`/track/${track.trackId}`}>
              <div className="track-image">
                <img src={getYouTubeThumbnail(track.youtubeLink)} alt={track.name} />
                <div className="track-overlay">
                  <div className="play-button">
                    <FaPlay />
                  </div>
                </div>
              </div>
              <div className="track-info">
                <h3>{track.name}</h3>
                <div className="track-xp">
                  <FaStar className="xp-star" />
                  <span>{track.xp} XP</span>
                </div>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default TrackListingPage;