import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrophy } from 'react-icons/fa';
import '../styles/JAMZComponents.css';

function JAMZRewardPopup({ isOpen, onClose, jamzAmount, timesRewarded, maxDailyRewards, message, isJAMZAwarded }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="jamz-reward-popup-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="jamz-reward-popup"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
          >
            <button className="close-btn" onClick={onClose}>&times;</button>
            <FaTrophy className="trophy-icon" />
            <h2>{isJAMZAwarded ? 'JAMZ Earned!' : 'No JAMZ Earned'}</h2>
            {isJAMZAwarded && <p className="jamz-amount">{jamzAmount} JAMZ</p>}
            <p>{message}</p>
            <p>Times rewarded today: {timesRewarded}/{maxDailyRewards}</p>
            <button className="btn" onClick={onClose}>Close</button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default JAMZRewardPopup;