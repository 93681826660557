import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const SectionWithAnimation = ({ children, title }) => {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.3 });
    return (
      <motion.section
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
        aria-labelledby={`section-${title.toLowerCase().replace(/\s+/g, '-')}`}
      >
        <h2 id={`section-${title.toLowerCase().replace(/\s+/g, '-')}`}>{title}</h2>
        {children}
      </motion.section>
    );
  };

  return (
    <motion.div 
      className="privacy-policy-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="privacy-policy-wrapper">
        <motion.h1 
          className="privacy-policy-title"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
        >
          Privacy Policy
        </motion.h1>
        <motion.div 
          className="privacy-policy-content"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          <SectionWithAnimation title="1. Introduction">
            <p>Welcome to Jamz.fun. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data and tell you about your privacy rights.</p>
          </SectionWithAnimation>

          <SectionWithAnimation title="2. Data We Collect">
            <p>We may collect, use, store and transfer different kinds of personal data about you, including:</p>
            <ul>
              <li>Identity Data</li>
              <li>Contact Data</li>
              <li>Technical Data</li>
              <li>Usage Data</li>
              <li>Profile Data</li>
            </ul>
          </SectionWithAnimation>

          <SectionWithAnimation title="3. How We Use Your Data">
            <p>We use your data to provide and improve our services, including:</p>
            <ul>
              <li>To register you as a new user</li>
              <li>To process and deliver your requests</li>
              <li>To manage our relationship with you</li>
              <li>To improve our website, products/services, marketing or customer relationships</li>
            </ul>
          </SectionWithAnimation>

          <SectionWithAnimation title="4. Data Security">
            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.</p>
          </SectionWithAnimation>

          <SectionWithAnimation title="5. Your Legal Rights">
            <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access, correction, erasure, restriction, transfer, or to object to processing.</p>
          </SectionWithAnimation>

          <SectionWithAnimation title="6. Changes to This Privacy Policy">
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          </SectionWithAnimation>

          <SectionWithAnimation title="7. Contact Us">
            <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:privacy@jamz.fun">privacy@jamz.fun</a></p>
          </SectionWithAnimation>
        </motion.div>
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.button
            className="back-to-top"
            onClick={scrollToTop}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            aria-label="Scroll to top of page"
            title="Back to top"
          >
            <span aria-hidden="true">↑</span>
          </motion.button>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default PrivacyPolicy;