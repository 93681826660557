import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBars, FaHome, FaSignInAlt, FaUserPlus, FaSignOutAlt, FaUser, FaWallet } from 'react-icons/fa';
import { AuthContext, JAMZContext } from '../App';  // Adjust the import path as needed
import SideMenu from './SideMenu';
import '../styles/HeaderSideMenu.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const { userJAMZ, isLoadingJAMZ } = useContext(JAMZContext);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleWalletClick = () => {
    navigate('/wallet');
  };

  return (
    <>
      <motion.header
        className="global-header"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
      >
        <div className="container header-content">
          <motion.div
            className="logo"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to="/">
              <img src="/jamz-fun.png" alt="Jamz.fun Logo" />
              <span>Jamz.fun</span>
            </Link>
          </motion.div>
          <nav className="desktop-nav">
            <ul>
              <li><Link to="/"><FaHome /> Home</Link></li>
              {isAuthenticated ? (
                <>
                  <li>
                    <Link to={`/profile/${user}`} className="profile-link">
                      <FaUser className="profile-icon" />
                      <span>{user}</span>
                    </Link>
                  </li>
                  <li>
                    <motion.div 
                      className="wallet-display"
                      onClick={handleWalletClick}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FaWallet className="wallet-icon" />
                      <span>
                        {isLoadingJAMZ 
                          ? 'Loading...' 
                          : `${userJAMZ.jamz !== undefined ? userJAMZ.jamz.toFixed(2) : '0.00'} JAMZ`
                        }
                      </span>
                    </motion.div>
                  </li>
                  <li><button onClick={handleLogout}><FaSignOutAlt /> Logout</button></li>
                </>
              ) : (
                <>
                  <li><Link to="/login"><FaSignInAlt /> Login</Link></li>
                  <li><Link to="/signup"><FaUserPlus /> Sign Up</Link></li>
                </>
              )}
            </ul>
          </nav>
          <motion.button
            className="menu-toggle"
            onClick={toggleMenu}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaBars />
          </motion.button>
        </div>
      </motion.header>
      <SideMenu
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        userJamzBalance={userJAMZ ? userJAMZ.jamz : 0}
        isLoadingJAMZ={isLoadingJAMZ}
        username={user}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
        handleWalletClick={handleWalletClick}
      />
    </>
  );
}

export default Header;