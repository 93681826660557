import React from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaTimes, FaUserPlus, FaSignInAlt } from 'react-icons/fa';
import '../styles.css';

const AuthPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="auth-popup-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="auth-popup"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: 'spring', damping: 25, stiffness: 500 }}
          >
            <button className="close-btn" onClick={onClose}>
              <FaTimes />
            </button>
            <h2>Join the Fun, Earn your Jamz!</h2>
            <p>Log in or sign up to like, comment and earn on tracks.</p>
            <div className="auth-buttons">
              <Link to="/login" className="btn btn-primary">
                <FaSignInAlt /> Log In
              </Link>
              <Link to="/signup" className="btn btn-secondary">
                <FaUserPlus /> Sign Up
              </Link>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default AuthPopup;