import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';

function AdminChallengeManagement() {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [removingChallenges, setRemovingChallenges] = useState({});
  const [actionLoading, setActionLoading] = useState({});

  useEffect(() => {
    fetchChallenges();
  }, [page]);

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(`https://app.jamz.fun/api/challenges/admin?page=${page}&limit=10`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setChallenges(prevChallenges => [...prevChallenges, ...response.data]);
      setLoading(false);
      if (response.data.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      setError(`Failed to fetch challenges: ${error.response?.data?.message || error.message}`);
      setLoading(false);
    }
  };

  const showConfirmDialog = (action, challengeId) => {
    setConfirmAction({ action, challengeId });
  };

  const handleConfirm = async () => {
    setActionLoading({ ...actionLoading, [confirmAction.challengeId]: true });
    setRemovingChallenges({ ...removingChallenges, [confirmAction.challengeId]: true });
    if (confirmAction.action === 'approve') {
      await handleApprove(confirmAction.challengeId);
    } else if (confirmAction.action === 'reject') {
      await handleReject(confirmAction.challengeId);
    }
    setConfirmAction(null);
  };

  const handleApprove = async (challengeId) => {
    try {
      await axios.put(`https://app.jamz.fun/api/challenges/${challengeId}/approve`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSuccessMessage('Challenge approved successfully');
      console.log('Challenge approved:', challengeId);
      
      // Start the removal animation
      setRemovingChallenges({ ...removingChallenges, [challengeId]: true });
      
      // Wait for the animation to complete before removing from state
      setTimeout(() => {
        setChallenges(challenges.filter(c => c._id !== challengeId));
        setRemovingChallenges(prev => ({ ...prev, [challengeId]: false }));
        console.log('Challenge removed from state:', challengeId);
        
        // Clear success message after a delay
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      }, 500); // Adjust this timing to match your animation duration
    } catch (error) {
      setError(`Failed to approve challenge: ${error.response?.data?.message || error.message}`);
    } finally {
      setActionLoading({ ...actionLoading, [challengeId]: false });
    }
  };

  const handleReject = async (challengeId) => {
    try {
      await axios.put(`https://app.jamz.fun/api/challenges/${challengeId}/reject`, {}, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      setSuccessMessage('Challenge rejected successfully');
      console.log('Challenge rejected:', challengeId);
      
      // Start the removal animation
      setRemovingChallenges({ ...removingChallenges, [challengeId]: true });
      
      // Wait for the animation to complete before removing from state
      setTimeout(() => {
        setChallenges(challenges.filter(c => c._id !== challengeId));
        setRemovingChallenges(prev => ({ ...prev, [challengeId]: false }));
        console.log('Challenge removed from state:', challengeId);
        
        // Clear success message after a delay
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      }, 500); // Adjust this timing to match your animation duration
    } catch (error) {
      setError(`Failed to reject challenge: ${error.response?.data?.message || error.message}`);
    } finally {
      setActionLoading({ ...actionLoading, [challengeId]: false });
    }
  };

  if (loading) return <div className="loading"><FaSpinner className="spinner" /> Loading...</div>;
  if (error) return <div>{error}</div>;

  const loadMore = () => {
    setChallenges([]);
    setPage(prevPage => prevPage + 1);
  };

  return (
    <motion.div
      className="admin-challenge-management-container"
      className="admin-challenge-management"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2>Challenge Management</h2>
      <AnimatePresence>
        {error && (
          <motion.div className="error-message" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} key="error">
            {error}
          </motion.div>
        )}
        {successMessage && (
          <motion.div className="success-message" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} key="success">
            <p>{successMessage}</p>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {challenges.map((challenge) => (
          <motion.div
            key={challenge._id}
            className="challenge-item card"
            initial={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -50, scale: 0.9 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            animate={{ opacity: removingChallenges[challenge._id] ? 0 : 1, y: removingChallenges[challenge._id] ? -50 : 0, scale: removingChallenges[challenge._id] ? 0.9 : 1 }}
          >
            <h3 className="challenge-title">Challenge ID: {challenge._id}</h3>
            <p className="challenge-info">Track ID: {challenge.trackId}</p>
            <p className="challenge-info">User ID: {challenge.userId}</p>
            <p className="challenge-info">Platform: {challenge.platform}</p>
            <p className="challenge-info">Link: <a href={challenge.link} target="_blank" rel="noopener noreferrer">View Challenge</a></p>
            <p className="challenge-info">Reward: {challenge.rewardType} {challenge.jamz}</p>
            <p className="challenge-status">Status: {challenge.status}</p>
            {challenge.status === 'pending' && (
              <div className="challenge-actions">
                <motion.button
                  className="btn btn-primary"
                  onClick={() => showConfirmDialog('approve', challenge._id)}
                  disabled={actionLoading[challenge._id]}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaCheck /> Approve
                </motion.button>
                <motion.button
                  className="btn btn-secondary"
                  onClick={() => showConfirmDialog('reject', challenge._id)}
                  disabled={actionLoading[challenge._id]}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaTimes /> Reject
                </motion.button>
              </div>
            )}
          </motion.div>
        ))}
      </AnimatePresence>
      {hasMore && (
        <motion.button
          className="btn btn-primary load-more"
          onClick={loadMore}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Load More
        </motion.button>
      )}
      <AnimatePresence>
        {confirmAction && (
          <motion.div
            className="confirm-dialog-overlay"
            initial={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
            animate={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
            exit={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
          >
            <motion.div
              className="confirm-dialog"
              initial={{ y: -50, opacity: 0, scale: 0.9 }}
              transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
            >
              <h3>Confirm Action</h3>
              <p>Are you sure you want to {confirmAction.action} this challenge?</p>
              <div className="confirm-dialog-actions">
                <motion.button
                  className="btn btn-primary"
                  onClick={handleConfirm}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Confirm
                </motion.button>
                <motion.button
                  className="btn btn-secondary"
                  onClick={() => setConfirmAction(null)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Cancel
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

const styles = `
.admin-challenge-management-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.confirm-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
}

.confirm-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  text-align: center;
  position: relative;
  z-index: 1001;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.confirm-dialog h3 {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.confirm-dialog-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.confirm-dialog-actions button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  border-radius: 4px;
}

.confirm-dialog-actions button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.challenge-item {
  transition: all 0.5s ease-out;
  margin-bottom: 30px;
  padding: 20px;
  opacity: 1;
  transform-origin: center top;
}

.error-message,
.success-message {
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-weight: bold;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  text-align: center;
}

.spinner {
  animation: spin 1s linear infinite;
  margin-right: 5px;
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
export default AdminChallengeManagement;