import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/NFTSection.css';
import MarketingEcosystemFund from './MarketingEcosystemFund';
import { motion, AnimatePresence } from 'framer-motion';
import { FaMusic, FaRocket, FaCoins, FaCopy, FaBook } from 'react-icons/fa';
import { AuthContext, JAMZContext } from '../App';

function Home() {
  const tracksRef = useRef(null);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userJAMZ, isLoadingJAMZ } = useContext(JAMZContext);
  const { isAuthenticated, token, username } = useContext(AuthContext);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const response = await axios.get('https://app.jamz.fun/api/tracks');
        setTracks(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tracks:', error);
        setError('Failed to load tracks. Please try again later.');
        setLoading(false);
      }
    };
    fetchTracks();
  }, []);

  const getYouTubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  const scrollToTracks = () => {
    if (tracksRef.current) {
      tracksRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const DynamicBanner = () => {
    return (
      <div className="dynamic-banner">
        <motion.div
          className="banner-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.div className="banner-left">
            <motion.h1
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            >
              Welcome to <span className="highlight">Jamz</span>.<span className="fun">Fun</span>
            </motion.h1>
            <motion.p
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
               Jamz.fun is a gamified music promotion platform on TRON & TON blockchain. We aim to transform how music is promoted by directly connecting artists, labels, and fans through interactive, reward-based engagement.
            </motion.p>
            <Link
              to={isAuthenticated ? "#" : "/signup"} 
              onClick={(e) => {
                if (isAuthenticated && tracksRef.current) {
                  e.preventDefault();
                  scrollToTracks();
                }
              }}
            >
              <motion.button
                className="cta-button"
                whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.5)" }}
                whileTap={{ scale: 0.95 }}>
                {isAuthenticated ? "Explore Tracks" : "Start Your Journey"}
              </motion.button>
            </Link>
          </motion.div>
          <motion.div 
            className="banner-right"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <div className="animated-vinyl">
              {/* SVG or image of a vinyl record that spins */}
            </div>
            <div className="floating-notes">
              <span className="note">♪</span>
              <span className="note">♫</span>
              <span className="note">♩</span>
              <span className="note">♬</span>
            </div>
          </motion.div>
        </motion.div>
      </div>
    );
  };

  const PresaleSection = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [trxAmount, setTrxAmount] = useState('');
    const [jamzAmount, setJamzAmount] = useState('');
    const [selectedChain, setSelectedChain] = useState('TRON');
    const [copied, setCopied] = useState(false);

    const handleCalculatorInput = (e) => {
      e.preventDefault();
      const value = e.target.value;
      setTrxAmount(value);
      const calculatedJamz = (parseFloat(value) / 0.00025);
      setJamzAmount(isNaN(calculatedJamz) ? '0.00' : formatNumber(calculatedJamz));
    };

    return (
      <div className="presale-section-wrapper">
        <motion.section
          className="presale-section"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="presale-content">
              <h2>Jamz.Fun Private Presale</h2>
            <p>Don't miss out! The JAMZ presale is live - this is your chance to be part of the next big thing in music and rewards. Heading straight to the SUN!.</p>
            <div className="presale-features">
              <div className="presale-feature">
                <FaRocket />
                <h3>Early Access</h3>
                <p>Be among the first to own JAMZ tokens</p>
              </div>
              <div className="presale-feature">
                <FaCoins />
                <h3>Exclusive Rates</h3>
                <p>Get JAMZ at the lowest price it will ever be during presale</p>
              </div>
              <div className="presale-feature">
                <FaMusic />
                <h3>Shape the Future</h3>
                <p>Influence the platform's development</p>
              </div>
            </div>
            <motion.button
              className="join-presale-btn"
              whileHover={{ scale: 1.05, boxShadow: "0px 0px 25px rgba(255,255,255,0.6)" }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowDetails(!showDetails)}
            >
              {showDetails ? 'Hide Details' : 'Join Presale'}
            </motion.button>
          </div>

          <AnimatePresence>
            {showDetails && (
              <motion.div
                className="presale-details"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className="presale-details-content">
                  <h3>How to Participate</h3>
                  <ol className="participation-steps">
                     <li>Select the blockchain network:</li>
                     <div className="select-wrapper">
                       <select value={selectedChain} onChange={(e) => setSelectedChain(e.target.value)}>
                         <option value="TRON">TRON</option>
                         <option value="BNB">BNB Chain</option>
                         <option value="TON">TON</option>
                       </select>
                     </div>
                    <li>Send USDT to the presale address below.</li>
                    <li>You will receive JAMZ tokens in return at TGE.</li>
                    <li>The exchange rate is 1 JAMZ = $0.00025</li>
                  </ol>

                  <div className="presale-address-section">
                    <h4>Presale Address (Send USDT here)</h4>
                    <div className="presale-address">
                      <input type="text" value={
                        selectedChain === 'TRON' ? 'TNo9S2yHupfoizhT2y9zhadonVMiKVE6mb' :
                        selectedChain === 'BNB' ? '0x917204AEB8Db15d7b7f21E0eD5aeDE767ce57952' :
                        'UQDakqmrkD8oQoCRg-xbQJ5H_a44v66CnGL2Flx8BhtnB_Le'
                      } readOnly />
                      <motion.button
                        whileHover={{ scale: 1.05, backgroundColor: "#5b4bc4" }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            selectedChain === 'TRON' ? 'TNo9S2yHupfoizhT2y9zhadonVMiKVE6mb' :
                            selectedChain === 'BNB' ? '0x917204AEB8Db15d7b7f21E0eD5aeDE767ce57952' :
                            'UQDakqmrkD8oQoCRg-xbQJ5H_a44v66CnGL2Flx8BhtnB_Le'
                          );
                          setCopied(true);
                          setTimeout(() => setCopied(false), 2000);
                        }}
                        className="copy-button"
                      >
                        <FaCopy /> {copied ? 'Copied!' : 'Copy'}
                      </motion.button>
                    </div>
                    {copied && <p className="copy-notification">Address copied to clipboard!</p>}
                  </div>

                  <div className="jamz-calculator">
                    <h4>JAMZ Calculator</h4>
                    <input 
                      type="number" 
                      value={trxAmount} 
                      onChange={handleCalculatorInput}
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter USDT amount"
                    />
                    <p>You will receive: {jamzAmount} JAMZ</p>
                  </div>

                  <div className="jamz-calculator">
                    <h4>Learn More About JAMZ</h4>
                    <p>Discover the full potential of JAMZ tokens and how it's shaping the music marketing industry.</p>
                    <div className="funpaper-button-container">
                      <motion.a
                        href="https://jamz-fun.gitbook.io/funpaper"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="read-funpaper-btn"
                        whileHover={{
                          scale: 1.05,
                          boxShadow: "0px 0px 25px rgba(108, 92, 231, 0.8)",
                          backgroundColor: "#8c7ae6"
                        }}
                        whileTap={{ scale: 0.95 }}
                        initial={{
                          backgroundColor: "#6c5ce7",
                          color: "#ffffff",
                          padding: "14px 28px",
                          borderRadius: "30px",
                          fontSize: "20px",
                          fontWeight: "bold",
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textDecoration: "none",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                          transition: "all 0.3s ease"
                        }}
                      >
                        <FaBook style={{ marginRight: "12px", fontSize: "24px" }} />
                        Read the Jamz FunPaper
                      </motion.a>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.section>
      </div>
    );
  };

  const NFTSection = () => {
    return (
     <motion.section
       id="nft-section"
       className="nft-section"
       initial={{ opacity: 0, y: 20 }}
       animate={{ opacity: 1, y: 0 }}
       transition={{ duration: 0.6 }}
     >
       <div className="nft-content">
         <h2>Jamzfun OG NFT DAO Pass</h2>
         <div className="nft-columns">
           <div className="nft-video-column">
             <div className="nft-video-container">
               <video autoPlay loop muted playsInline className="nft-video" poster="/jamznft-poster.jpg">
                 <source src="/jamznft.mp4" type="video/mp4" />
                 Your browser does not support the video tag.
               </video>
             </div>
           </div>
           <div className="nft-description-column">
             <p className="nft-description">
               Jamz.fun is a gamified, meme-powered music platform on TRON blockchain, 
               where fans earn rewards for engaging with their favorite artists tracks. 
               The OG NFT DAO Pass gives access to join the team exclusive group to meet 
               Artists onboarded, get tickets to concerts and events plus crowdfund 
               Artists Projects, JAMZ Presale discount and more.
             </p>
             <motion.button
               className="purchase-nft-btn cta-button"
               whileHover={{ scale: 1.05, boxShadow: "0px 0px 15px rgba(255,255,255,0.5)" }}
               whileTap={{ scale: 0.95 }}
               onClick={() => {
                 window.location.href = "https://nftpump.meme/#/pumpCollection/TSfeDBhj8edB31MhymHai7WX7g9uSQG3fQ";
               }}
             >
               Purchase NFT
             </motion.button>
           </div>
         </div>
       </div>
     </motion.section>
    );
  };

  if (loading) return (
    <div className="home">
      <div className="container">
        <div className="loading skeleton">
          <div className="skeleton-header"></div>
          <div className="skeleton-features"></div>
          <div className="skeleton-tracks"></div>
        </div>
      </div>
    </div>
  );
  
  if (error) return (
    <div className="home">
      <div className="container">
        <div className="error">{error}</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="home">
        <motion.div
          className="home-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <DynamicBanner key={`dynamic-banner-${isAuthenticated}`} />
          
          <div className="container">
            <motion.div
              ref={tracksRef}
              key={`tracks-section-${isAuthenticated}`}
              id="tracks-section"
              className="latest-tracks-section"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <h2 className="latest-tracks-title">Latest Tracks</h2>
              <motion.p
                className="latest-tracks-description"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                Discover our latest track drops below and start earning TRX & JAMZ tokens as you stream & engage! Each stream rewards both you and the artist. The more you stream and engage, the more you earn. Dive into our curated selection below and turn your passion for music into real value!
              </motion.p>
            </motion.div>

            <motion.div
              className="tracks-grid"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {tracks.map((track, index) => (
                <motion.div
                  key={track.trackId}
                  className="track-item"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * index }}
                >
                  <Link to={`/track/${track.trackId}`}> 
                    <img 
                      src={track.thumbnail || `https://img.youtube.com/vi/${getYouTubeID(track.youtubeLink)}/0.jpg`} 
                      alt={track.name} 
                    />
                    <h3>{track.name}</h3>
                  </Link>
                </motion.div>
              ))}
            </motion.div>
          </div>

          <NFTSection />
          <PresaleSection />
          <MarketingEcosystemFund />
        </motion.div>
      </div>
    </>
  );
}

export default Home;