import React, { useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaCoins, FaHistory, FaSpinner, FaArrowRight, FaCheckCircle, FaExclamationTriangle, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import '../styles/Wallet.css';
import { AuthContext } from '../App';

const FancyConfirmationPopup = ({ details, onClose }) => (
  <motion.div 
    className="fancy-confirmation-overlay"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    onClick={onClose}
  >
    <motion.div 
      className="fancy-confirmation-popup"
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.5, opacity: 0 }}
      transition={{ type: "spring", damping: 15 }}
      onClick={(e) => e.stopPropagation()}
    >
      <motion.button 
        className="close-button"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={onClose}
      >
        <FaTimes />
      </motion.button>
      <motion.div 
        className="success-icon-wrapper"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.2, type: "spring", damping: 10 }}
      >
        <FaCheckCircle className="success-icon" />
      </motion.div>
      <motion.h2
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        Withdrawal Request Submitted
      </motion.h2>
      <motion.p
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
         Your withdrawal request for {details.amount} {details.token} to USDT address {details.trxAddress} has been submitted and is pending approval.
      </motion.p>
      <motion.button
        className="close-confirmation-button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClose}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        Close
      </motion.button>
    </motion.div>
  </motion.div>
);

function Wallet() {
   const [balances, setBalances] = useState({ jamzBalance: 0, usdtBalance: 0 });
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawToken, setWithdrawToken] = useState('JAMZ');
   const [usdtAddress, setUsdtAddress] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [showWithdrawForm, setShowWithdrawForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState(null);
  const [withdrawals, setWithdrawals] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await fetchBalances();
        await fetchWithdrawals();
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const fetchBalances = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get('https://app.jamz.fun/api/users/wallet', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Wallet data received:', response.data);
      setBalances(response.data);
    } catch (error) {
      console.error('Error fetching balances:', error);
      setError('Failed to fetch wallet balances. Please try again.');
      if (error.response && error.response.status === 401) {
        handleInvalidToken('Your session has expired. Please log in again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const response = await axios.get('https://app.jamz.fun/api/withdrawals', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setError('Failed to fetch withdrawal history. Please try again.');
    }
  };

  const handleInvalidToken = (message) => {
    setError(message);
  };

  const handleWithdraw = async () => {
    if (!token) {
      handleInvalidToken('You are not authenticated. Please log in.');
      return;
    }
    if (!withdrawAmount || isNaN(withdrawAmount) || parseFloat(withdrawAmount) <= 0) {
      setError('Please enter a valid amount to withdraw.');
      return;
    }
     if (!usdtAddress) {
       setError('Please enter a valid USDT address for withdrawal.');
      return;
    }

    setIsWithdrawing(true);
    setError('');
    try {
      const response = await axios.post('https://app.jamz.fun/api/withdrawals', {
        amount: parseFloat(withdrawAmount),
        token: withdrawToken,
         usdtAddress: usdtAddress
      }, { 
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('Withdrawal response:', response.data);
      setConfirmationDetails({
        amount: withdrawAmount,
        token: withdrawToken,
         usdtAddress: usdtAddress,
        status: 'pending'
      });
      setShowConfirmation(true);
      setWithdrawAmount('');
      setShowWithdrawForm(false);
       setUsdtAddress('');
      fetchBalances();
      fetchWithdrawals();
    } catch (error) {
      console.error('Error submitting withdrawal request:', error);
      setError(error.response?.data?.message || 'Error submitting withdrawal request. Please try again.');
    } finally {
      setIsWithdrawing(false);
    }
  };

  const handleCancelWithdrawal = async (id) => {
    try {
      await axios.delete(`https://app.jamz.fun/api/withdrawals/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchWithdrawals();
      fetchBalances();
    } catch (error) {
      console.error('Error cancelling withdrawal:', error);
      setError('Failed to cancel withdrawal. Please try again.');
    }
  };

  const BalanceCard = ({ token, balance }) => (
    <motion.div 
      className={`balance-card ${token.toLowerCase()}`}
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h2>{token}</h2>
      <div className="balance">
        <FaCoins />
        <span>{(balance || 0).toLocaleString()}</span>
      </div>
    </motion.div>
  );

  const WithdrawalHistory = () => (
    <div className="withdrawal-history">
      <h2>Withdrawal History</h2>
      {withdrawals.map((withdrawal) => (
        <div key={withdrawal._id} className="withdrawal-item">
          <p>{withdrawal.amount} {withdrawal.token} to {withdrawal.trxAddress}</p>
          <p>Status: {withdrawal.status}</p>
          {withdrawal.status === 'pending' && (
            <button onClick={() => handleCancelWithdrawal(withdrawal._id)}>Cancel</button>
          )}
        </div>
      ))}
    </div>
  );

  if (isLoading) {
    return (
      <div className="wallet-container loading">
        <FaSpinner className="spinner" />
        <p>Loading wallet...</p>
      </div>
    );
  }

  if (!token) {
    return (
      <div className="wallet-container">
        <h1>Wallet Access Denied</h1>
        <p>Please log in to view your wallet.</p>
        <button onClick={() => navigate('/login')}>Go to Login</button>
      </div>
    );
  }

  return (
    <motion.div 
      className="wallet-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1>Your Wallet</h1>
      
      {error && (
        <motion.p className="error-message" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <FaExclamationTriangle />
          {error}
        </motion.p>
      )}
      <div className="balance-cards">
        <BalanceCard token="JAMZ" balance={balances.jamzBalance} />
         <BalanceCard token="USDT" balance={balances.usdtBalance} />
      </div>
      <div className="wallet-actions">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="action-button withdraw"
          onClick={() => {
            setError('');
            setShowWithdrawForm(!showWithdrawForm);
          }}
        >
          <FaArrowRight /> Withdraw
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="action-button history"
          onClick={() => setShowHistory(!showHistory)}
        >
          <FaHistory /> {showHistory ? 'Hide History' : 'Show History'}
        </motion.button>
      </div>
      {showWithdrawForm && (
        <div className="withdraw-section">
          <h2>Withdraw Tokens</h2>
          <div className="withdraw-form">
            <input 
              type="number" 
              value={withdrawAmount} 
              onChange={(e) => setWithdrawAmount(e.target.value)} 
              placeholder="Amount to withdraw"
            />
            <select value={withdrawToken} onChange={(e) => setWithdrawToken(e.target.value)}>
              <option value="JAMZ">JAMZ</option>
               <option value="USDT">USDT</option>
            </select>
            <input 
              type="text" 
               value={usdtAddress} 
               onChange={(e) => setUsdtAddress(e.target.value)} 
               placeholder="Enter USDT address"
              required
            />
            <motion.button
              onClick={handleWithdraw}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="action-button withdraw"
              disabled={isWithdrawing}
            >
              {isWithdrawing ? <FaSpinner className="spinner" /> : <FaArrowRight />} 
              {isWithdrawing ? 'Submitting...' : 'Submit Withdrawal'}
            </motion.button>
          </div>
        </div>
      )}
      {showHistory && <WithdrawalHistory />}
      <AnimatePresence>
        {showConfirmation && (
          <FancyConfirmationPopup 
            details={confirmationDetails} 
            onClose={() => setShowConfirmation(false)} 
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Wallet;