import React from 'react';
import { FaTelegram, FaDiscord, FaYoutube, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h3>About Jamz</h3>
          <p>Jamz.fun is the go-to platform for earning rewards through music, connecting artists and fans globally while having fun.</p>
        </div>
        <div className="footer-section links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/tracks">Tracks</a></li>
            <li><a href="https://jamz-fun.gitbook.io/funpaper" target="_blank" rel="noopener noreferrer">FunPaper</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <p>Email: support@jamz.fun</p>
          <div className="social-icons">
            <a href="https://t.me/jamzdotfun" target="_blank" rel="noopener noreferrer"><FaTelegram /></a>
            <a href="https://discord.gg/jamzdotfun" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
            <a href="https://twitter.com/jamzdotfun" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.instagram.com/jamz.fun" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            <a href="https://www.youtube.com/@jamzdotfun" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Jamz. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;