import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCheckCircle, FaTiktok, FaInstagram, FaYoutube } from 'react-icons/fa';

function ChallengeSubmissionFeedback({ isOpen, onClose, platform }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const modalVariants = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: { y: "0", opacity: 1, transition: { type: "spring", damping: 25, stiffness: 500 } }
  };

  const getPlatformIcon = () => {
    switch (platform) {
      case 'tiktok': return <FaTiktok />;
      case 'instagram': return <FaInstagram />;
      case 'youtube': return <FaYoutube />;
      default: return null;
    }
  };
 
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="popup-overlay"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={onClose}
        >
          <motion.div
            className="popup-content challenge-submission-feedback"
            variants={modalVariants}
            onClick={(e) => e.stopPropagation()}
          >
            <FaCheckCircle className="success-icon" />
            <h2>Challenge Submitted!</h2>
            <div className="platform-icon">{getPlatformIcon()}</div>
            <p>Your {platform} challenge has been successfully submitted for review.</p>
            <p>Our team will evaluate your submission and update your reward accordingly.</p>
            <p>Keep creating awesome content!</p>
            <motion.button
              className="btn btn-primary"
              onClick={onClose}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Got it!
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ChallengeSubmissionFeedback;