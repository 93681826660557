import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import '../styles/ArtistPage.css'; // This line is already correct
import { FaUser, FaMusic, FaTwitter, FaInstagram, FaYoutube, FaTiktok, FaCompactDisc, FaArrowLeft, FaHeart, FaShareAlt } from 'react-icons/fa';

function ArtistPage() {
  const { artistId } = useParams();
  const [artist, setArtist] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  
  useEffect(() => {
    const fetchArtistAndTracks = async () => {
      try {
        const [artistResponse, tracksResponse] = await Promise.all([
          axios.get(`https://app.jamz.fun/api/artists/${artistId}`),
          axios.get(`https://app.jamz.fun/api/artists/${artistId}/tracks`)
        ]);
        setArtist(artistResponse.data);
        setTracks(tracksResponse.data);
        const statsResponse = await axios.get(`https://app.jamz.fun/api/artists/${artistId}/stats`);
        setStats(statsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching artist data:', error);
        setError('Failed to load artist information. Please try again later.');
        setLoading(false);
      }
    };

    fetchArtistAndTracks();
  }, [artistId]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!artist) return <div className="error">Artist not found</div>;

  const socialLinks = [
    { name: 'Twitter', icon: <FaTwitter />, url: artist.twitter },
    { name: 'Instagram', icon: <FaInstagram />, url: artist.instagram },
    { name: 'YouTube', icon: <FaYoutube />, url: artist.youtube },
    { name: 'TikTok', icon: <FaTiktok />, url: artist.tiktok }
  ].filter(link => link.url);

  const containerVariants = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { duration: 0.5, staggerChildren: 0.1 } } };
  const itemVariants = { hidden: { y: 20, opacity: 0 }, visible: { y: 0, opacity: 1, transition: { type: 'spring', stiffness: 100 } } };

  const gradientOverlay = 'linear-gradient(to bottom, var(--overlay-color-dark) 0%, var(--overlay-color-medium) 50%, var(--overlay-color-dark) 100%)';

  return (
    <motion.div 
      className="artist-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      style={{ backgroundImage: `${gradientOverlay}, url(${artist.imageUrl})` }}
    >
      <motion.div 
        className="artist-header"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="back-button" variants={itemVariants}>
          <Link to="/" className="back-link">
            <FaArrowLeft /> <span>Back</span>
          </Link>
        </motion.div>
        <motion.div 
          className="artist-header-content"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div className="artist-avatar-container" variants={itemVariants} whileHover={{ scale: 1.05, boxShadow: '0 0 30px rgba(29, 185, 84, 0.8)' }}>
          {artist.imageUrl ? (
            <img src={artist.imageUrl} alt={artist.name} className="artist-avatar" />
          ) : (
            <FaUser className="artist-avatar" />
          )}
        </motion.div>
        <motion.h1 variants={itemVariants}>{artist.name}</motion.h1>
        {artist.bio && <motion.p variants={itemVariants} className="artist-bio">{artist.bio}</motion.p>}
        
        {socialLinks.length > 0 && (
          <motion.div className="social-links" variants={containerVariants}>
            {socialLinks.map((link, index) => (
              <motion.a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {link.icon}
              </motion.a>
            ))}
          </motion.div>
        )}
        
        {stats && (
          <motion.div className="artist-stats" variants={containerVariants}>
            <motion.div variants={itemVariants} className="stat-item">
              <FaCompactDisc />
              <span>{stats.trackCount} Tracks</span>
            </motion.div>
            <motion.div variants={itemVariants} className="stat-item">
              <FaMusic />
              <span>{stats.totalJamz} JAMZ</span>
            </motion.div>
            <motion.div variants={itemVariants} className="stat-item">
              <FaHeart />
              <span>{stats.totalLikes} Likes</span>
            </motion.div>
            <motion.div variants={itemVariants} className="stat-item">
              <FaShareAlt />
              <span>{stats.totalShares} Shares</span>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
      </motion.div>
      
      <motion.div className="tracks-section" variants={containerVariants} initial="hidden" animate="visible" transition={{ delay: 0.5 }}>
        <motion.div className="tracks-container">
          <motion.h2 variants={itemVariants}>Tracks</motion.h2>
      <div className="tracks-list">
        {tracks.length > 0 ? (
          tracks.map(track => (
            <motion.div 
              key={track._id} 
              className="track-item"
              whileHover={{ scale: 1.05, backgroundColor: 'var(--hover-color)' }}
              whileTap={{ scale: 0.97 }}
              variants={itemVariants}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <Link to={`/track/${track._id}`}>
                <FaMusic className="track-icon" />
                <span className="track-name">{track.name}</span>
              </Link>
            </motion.div>
          ))
        ) : (
          <p>No tracks available for this artist.</p>
        )}
      </div>
      </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ArtistPage;